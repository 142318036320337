<template>
    <div>
                <v-icon small left :color="params.value == 'Draft' ?'blue':params.value == 'Approved'?'success':'orange'">fiber_manual_record</v-icon>
                {{params.value}}
    </div>
</template>
<script>
export default {

}
</script>